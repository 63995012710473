import React, { useEffect, useState } from "react";
import { db } from "../Firebase";
import { collection, getDocs, doc, getDoc, setDoc } from "firebase/firestore";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Forms from "../features/Forms.tsx";
import State from "../features/State.jsx";
import ThreadsNav from "./ThreadsNav";
import "./threads.css";

const Thread = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/calculation");
  }, []);
  const threadId = useParams();
  const location = useLocation();
  const [DBURL, setDBURL] = useState("");
  // const [userFilePath, setUserFilePath] = useState("");
  const [paramsData, setParamsData] = useState("");
  const [hashData, setHashData] = useState("");

  const [postName, setPostName] = useState("名無し");
  const [postTextArea, setPostTextArea] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setPostName(e.target.value);
  };

  const handleTextAreaChange = (e) => {
    setPostTextArea(e.target.value);
  };
  useEffect(() => {
    console.log(location);
    console.log(threadId);
    if (location.hash !== "") {
      window.location.href = location.hash;
      console.log("123456789" + location.hash);
    } else {
      return;
    }
  }, [location, threadId]);
  useEffect(() => {
    setDBURL(threadId.id);
  }, [threadId]);

  // useEffect(() => {
  //   console.log(DBURL);
  // }, [DBURL]);
  const getPostedData = async () => {
    const ThreadDataRef = doc(db, "thread", DBURL);
    const ThreadName = await getDoc(ThreadDataRef);
    setParamsData(ThreadName.data().threadName);
  };

  useEffect(() => {
    getPostedData();
  }, [DBURL]);

  const [posts, setPosts] = useState([]);

  // const [userName, setUserName] = useState("名無し");
  // const [userContent, setUserContent] = useState("");
  //   const sizeLimit = 1024 * 1024 * 2;

  useEffect(() => {
    const getPostedData = async () => {
      const newPostedData = [];
      const querySnapshot = await getDocs(collection(db, DBURL));
      querySnapshot.forEach((doc) => {
        newPostedData.push(doc.data());
      });
      setPosts(newPostedData);
    };
    getPostedData();
  }, [DBURL]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (check()) {
      const timestampNum = new Date().getTime();
      const uniqueTimeStamp = String(timestampNum);
      console.log(timestampNum);
      var date = new Date(timestampNum);
      const storedDate =
        date.getFullYear() +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2);
      const postingData = {
        displayedDate: storedDate,
        timestamp: timestampNum,
        uniqueId: uniqueTimeStamp,
        userContents: postTextArea,
        userName: postName,
      };
      const docRef = doc(db, DBURL, uniqueTimeStamp);
      await setDoc(docRef, postingData);
      setPosts([...posts, postingData]);
      setPostTextArea("");
      setErrorMessage("");
    }
  };

  const threadContent = (posts) => {
    const list = posts.map((p, index) => {
      return (
        <div className="tweet" key={index} id={p.uniqueId}>
          <div className="tweet-info">
            <p>
              <span className="tweet-num">{index + 1}:</span>
              <span className="tweet-name">{p.userName}</span>
              <span className="tweet-time">{p.displayedDate}</span>
            </p>
          </div>
          <div className="tweet-content">{p.userContents}</div>
          <div className="tweet-file">
            <img src={p.filePath} alt="" loading="lazy"></img>
          </div>
        </div>
      );
    });
    return list;
  };

  useEffect(() => {
    threadContent(posts);
  }, [posts]);

  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const check = () => {
    if (postName.length <= 0) {
      setErrorMessage("名前を入力してください");
      return false;
    } else if (postTextArea.length <= 0) {
      setErrorMessage("投稿コンテンツが含まれていません");
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <ThreadsNav />
      <h1 className="params_title">{paramsData}</h1>
      {/* <Forms threadId={DBURL} /> */}

      <State
        handleChange={handleChange}
        handleTextAreaChange={handleTextAreaChange}
        errorMessage={errorMessage}
        postName={postName}
        onSubmit={onSubmit}
        postTextArea={postTextArea}
      />
      {threadContent(posts)}
      <img
        className="btnToTop"
        src={`${process.env.PUBLIC_URL}/totop.png`}
        alt="top scroll"
        onClick={returnTop}
      />
      <fotter id="footer">foooooooooter</fotter>
    </>
  );
};

export default Thread;
