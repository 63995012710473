import React from "react";
class Explain extends React.Component {
  render() {
    return (
      <div className="explain-wrapper">
        <h2 className="explain-head">
          3ステップで取引手数料を計算してみよう！取引履歴をインポートするだけで簡単に計算できます。
        </h2>
        <div className="explain-items">
          <div className="explain-item">
            <img
              className="explain-img"
              src={`${process.env.PUBLIC_URL}/exchange_icon.jpg`}
              alt=""
            />
            <h3>1.取引所を選択する</h3>
            <p>
              Binance, OKX, MEXC Global, Bybitの4つの取引所に対応しています。
            </p>
          </div>
          <div className="explain-item">
            <img
              className="explain-img"
              src={`${process.env.PUBLIC_URL}/csv_icon.jpg`}
              alt=""
            />
            <h3>2.取引履歴をダウンロードする</h3>
            <p>
              選択した取引所から取引履歴のファイルをダウンロードしてください。
              <br />
              USDT無期限先物の手数料の計算にのみ対応しています。
            </p>
          </div>
          <div className="explain-item">
            <img
              className="explain-img"
              src={`${process.env.PUBLIC_URL}/bitcoin_hand_icon.jpg`}
              alt=""
            />
            <h3>3.取引履歴をインポートする</h3>
            <p>
              取引履歴のファイルをインポートすると、今までに支払った手数料を計算できます。
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default Explain;
