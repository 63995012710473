import { BigNumber } from "bignumber.js";
import { transaction, isEqualArray } from "../utils.tsx";

export const okx_fileType = "text/csv"; //OKX固有

const okx_header = [
  //OKX固有
  "﻿id",
  "Time",
  "Trade Type",
  "Instrument",
  "Type",
  "Amount",
  "Unit",
  "PL",
  "Fee",
  "Position Change",
  "Position Balance",
  "Balance Change",
  "Balance",
  "Unit",
];

function okx_validate(csvHeader: string[]) {
  if (isEqualArray(csvHeader, okx_header)) {
    return true;
  }
  return false;
}

export function okx_fee_calc(csvOutput: string) {
  const cashbackRates: number = 0.4; //OKX固有
  let trades: transaction[] = [];
  let totalFees = new BigNumber(0);
  const csvRows = csvOutput.slice(csvOutput.indexOf("\n") + 1).split("\n");
  const csvHeader = csvRows[0].split(",");
  if (okx_validate(csvHeader)) {
    const transactions = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object: transaction, header, index) => {
        object[index] = values[index];
        return object;
      }, {});
      return obj;
    });
    transactions.forEach((object) => {
      if (object[2] === "Swap") {
        //OKX固有
        if (object[13] === "USDT") {
          trades.push(object);
        }
      }
    });
    trades.forEach((object) => {
      if (object[8] !== undefined) {
        //OKX固有
        const fee = parseFloat(object[8].replace(/[^0-9.]/gi, "")); //OKX固有
        totalFees = totalFees.plus(fee);
      }
    });
    const cashbackFees = totalFees.multipliedBy(cashbackRates);
    const ticker = trades[0][3];

    let underlyingAsset = "USDT";
    return {
      trades: trades,
      totalFees: totalFees,
      cashbackFees,
      ticker,
      underlyingAsset,
    };
  } else {
    return {
      trades: trades,
      totalFees: totalFees,
      cashbackFees: totalFees,
      ticker: "ERROR",
      underlyingAsset: "ERROR",
    };
  }
}
