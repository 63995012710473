export interface transaction {
  [key: number|string]: string
}

export const isEqualArray = function (array1:string[], array2:string[]) {
  console.log(array1);
  console.log(array2);
  var i = array1.length;
  if (i !== array2.length) return false;
  console.log("checkpoint");
  while (i--) {
    console.log(i);
    console.log(array1[i], array2[i]);
    if (array1[i] !== array2[i]) return false;
  }
  console.log(true);
  return true;
};

//export type {transaction}