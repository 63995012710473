import { BigNumber } from "bignumber.js";
import { transaction, isEqualArray } from "../utils.tsx";

export const mexc_fileType = "text/csv"; //Mexc固有

const mexc_header = [
  //Mexc固有
  "Trade time",
  "Futures",
  "Direction",
  "Order amount",
  "Order price",
  "Fee",
  "P&L of closing position",
];

function fee_replace(txs: transaction[]) {
  let ans: transaction[] = [];
  txs.forEach((obj) => {
    let object = obj;
    if (obj[5] !== undefined) {
      object[5] = obj[5].replace(/[^0-9.]/gi, "");
      ans.push(object);
    }
  });
  return ans;
}

function mexc_validate(csvHeader: string[]) {
  if (isEqualArray(csvHeader, mexc_header)) {
    return true;
  }
  return false;
}

export function mexc_fee_calc(csvOutput: string) {
  const cashbackRates: number = 0.5; //Mexc固有
  let trades: transaction[] = [];
  let totalFees = new BigNumber(0);
  const csvHeader = csvOutput.slice(0, csvOutput.indexOf("\n")).split(",");
  if (mexc_validate(csvHeader)) {
    const csvRows = csvOutput.slice(csvOutput.indexOf("\n") + 1).split("\n");
    const txs = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object: transaction, header, index) => {
        object[index] = values[index];
        return object;
      }, {});
      return obj;
    });
    const transactions = fee_replace(txs);

    transactions.forEach((object) => {
      if (
        object[2] === "sell short" ||
        object[2] === "buy short" ||
        object[2] === "sell long" ||
        object[2] === "buy long"
      ) {
        //Mexc固有
        if (object[1].match(/USDT/)) {
          trades.push(object);
        }
      }
    });
    trades.forEach((object) => {
      if (object[5] !== undefined) {
        //Mexc固有
        const fee = parseFloat(object[5]); //Mexc固有
        totalFees = totalFees.plus(fee);
      }
    });
    const cashbackFees = totalFees.multipliedBy(cashbackRates);
    const ticker = trades[0][1];
    const underlyingAsset = "USDT";
    return {
      trades: trades,
      totalFees: totalFees,
      cashbackFees,
      ticker,
      underlyingAsset,
    };
  } else {
    return {
      trades: trades,
      totalFees: totalFees,
      cashbackFees: totalFees,
      ticker: "ERROR",
      underlyingAsset: "ERROR",
    };
  }
}
