import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ThreadsNav from "../components/ThreadsNav";
import "./main.css";

const Main = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/calculation");
  }, []);
  return (
    <>
      <ThreadsNav />
      <div className="howtouse">
        <h2 className="how_title">Crypto掲示板の使い方</h2>
        <div className="description-list">
          <ul>
            <li>
              掲示板はユーザー同士が情報交換をする場です。他人の権利を侵害したり、不快にさせる行動は控えましょう。
            </li>
            <li>
              個人情報は第三者に悪用される恐れがありますので投稿しないでください。
            </li>
            <li>イニシャルや友人関係等も個人を特定される恐れがあります。</li>
            <li>
              著作権侵害は犯罪にあたります。権利者の許可無く投稿することはやめましょう。
            </li>
            <li>
              匿名掲示板ですが、意図的に他人になりすまして投稿してはいけません。
            </li>
            <li>
              掲示板の利用は自己責任です。および掲示板で発生した損失や損害について、当社では一切責任を負いかねます。
            </li>
          </ul>
        </div>
      </div>
      <div className="footer_space"></div>
    </>
  );
};

export default Main;
