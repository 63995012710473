import React from "react";
import "./state.css";

const State = (props) => {
  return (
    <>
      <form onSubmit={props.onSubmit} className="form-post">
        <p className="username-wrapper">
          <label htmlFor="name">投稿名</label>
          <input
            type="text"
            name="name"
            onChange={props.handleChange}
            value={props.postName}
            className="form-username"
          ></input>
        </p>
        <textarea
          name="textarea"
          onChange={props.handleTextAreaChange}
          value={props.postTextArea}
          className="form-usercontents"
        ></textarea>
        <br />
        <button type="submit" id="test">
          投稿する
        </button>
      </form>
      <h2>{props.errorMessage}</h2>
    </>
  );
};

export default State;
