export const PostsData = [
    {
        id: 1,
        name: "ShinCode",
        content: "Redux勉強なう",
    },
    {
        id: 2,
        name: "名無しさん",
        content: "ShinCodeさん分かりやすい",
    },
    {
        id: 3,
        name: "駆け出しエンジニア",
        content: "Udemy受講してみようかな",
    },
    {
        id: 4,
        name: "スタックオーバーフローさん",
        content: "エンジニアのスタバ",
    },
];