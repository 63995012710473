import React, {useCallback, useState} from "react";
import "./calc.css";
import {BigNumber} from "bignumber.js";
import {bybit_fee_calc} from '../exchanges/Bybit.tsx';
import {binance_fee_calc} from '../exchanges/Binance.tsx';
import { okx_fee_calc } from "../exchanges/Okx.tsx";
import { mexc_fee_calc } from "../exchanges/Mexc.tsx";
import Explain from "../components/Explain.tsx";
import {transaction} from "../utils.tsx";
import {useDropzone} from "react-dropzone";

const exchange_list = [
  "Binance",
  "OKX",
  "MEXC Global",
  "Bybit"
]

function Calculation() {

  const [message, setMessage] = useState("取引所を選択してください");
  const [file, setFile] = useState(Object);
  const [exchange, setExchange] = useState("Binance");
  const [trades, setTrades] = useState(Array<transaction>);
  const [paidFees, setPaidFees] = useState(Number);
  const [cashbackFees, setCashbackFees] = useState(Number);
  const [ticker, setTicker] = useState(String);
  const [underlyingAsset, setUnderlyingAsset] = useState(String);
  const [error, setError] = useState(String);
  const [isOpenExchange, setIsOpenExchange] = useState(true);
  const [isOpenSelectFile, setIsOpenSelectFile] = useState(false);
  const [isOpenSendButton, setIsOpenSendButton] = useState(false);
  const [isOpenResult, setIsOpenResult] = useState(false);
  const [tweetUrl, setTweetUrl] = useState("https://twitter.com/intent/tweet?text=過去のトレードで取引所に支払った手数料を計算してみよう！%0a思ったよりたくさん払っているかも！？%0a%0ahttp://localhost:3000/%0a&via=CoinBack_jpn")
  const [tradeHistoryArticleUrl, setTradeHistoryArticleUrl] = useState("https://media.coinback-crypto.com/binance-download");


  const fileReader = new FileReader();

  const setDatas = (ans:{
    trades: transaction[];
    totalFees: BigNumber;
    cashbackFees: BigNumber;
    ticker: string;
    underlyingAsset: string;
}) => {
    setTrades(ans.trades);
    setPaidFees(ans.totalFees.toNumber());
    setCashbackFees(ans.cashbackFees.toNumber());
    setTicker(ans.ticker);
    setUnderlyingAsset(ans.underlyingAsset);
  }

  const handleChangeExchange = (e:any) => {
    setExchange(e.target.value);
    setIsOpenExchange(false);
    setIsOpenSelectFile(true);
    if(e.target.value === "Binance"){
      setTradeHistoryArticleUrl("https://media.coinback-crypto.com/binance-download");
    }else if(e.target.value === "OKX"){
      setTradeHistoryArticleUrl("https://media.coinback-crypto.com/okx-download");
    }else if(e.target.value === "MEXC Global"){
      setTradeHistoryArticleUrl("https://media.coinback-crypto.com/mexc-download");
    }else if(e.target.value === "Bybit"){
      setTradeHistoryArticleUrl("https://media.coinback-crypto.com/bybit-download");
    }
    setMessage("取引履歴を読み込んでください");
  }

  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0]);
    setIsOpenSelectFile(false);
    setIsOpenSendButton(true);
    setMessage("");
  }, []);

  const {getRootProps, getInputProps} = useDropzone({onDrop});

  const handleOnSubmit = async (e:any) => {
    e.preventDefault();

    if(file) {
      if(exchange === 'Binance'){
        const ans = await binance_fee_calc(file);
        setDatas(ans);
        const fee =  Math.floor(ans.totalFees.toNumber() * 10000) / 10000
        const asset = ans.underlyingAsset
        const url = "https://twitter.com/intent/tweet?text=過去のトレードで取引所に支払った手数料を計算してみよう！%0a私は過去に" + fee.toString() + asset + "の手数料を支払っていました。%0a%0ahttp://localhost:3000/%0a&via=CoinBack_jpn"
        setTweetUrl(url);
        setIsOpenSendButton(false);
        setIsOpenResult(true);
      }else{
        fileReader.onload = function(event) {
          if(event.target !== null){
            if(typeof event.target.result === "string"){
              const csvOutput = event.target.result;
              if(exchange === "Bybit"){
                const ans = bybit_fee_calc(csvOutput);
                setDatas(ans);
                const fee =  Math.floor(ans.totalFees.toNumber() * 10000) / 10000
                const asset = ans.underlyingAsset
                const url = "https://twitter.com/intent/tweet?text=過去のトレードで取引所に支払った手数料を計算してみよう！%0a私は過去に" + fee.toString() + asset + "の手数料を支払っていました。%0a%0ahttp://localhost:3000/%0a&via=CoinBack_jpn"
                setTweetUrl(url);
              }else if(exchange === "OKX"){
                const ans = okx_fee_calc(csvOutput);
                setDatas(ans);
                const fee =  Math.floor(ans.totalFees.toNumber() * 10000) / 10000
                const asset = ans.underlyingAsset
                const url = "https://twitter.com/intent/tweet?text=過去のトレードで取引所に支払った手数料を計算してみよう！%0a私は過去に" + fee.toString() + asset + "の手数料を支払っていました。%0a%0ahttp://localhost:3000/%0a&via=CoinBack_jpn"
                setTweetUrl(url);
              }else if(exchange === "MEXC Global"){
                const ans = mexc_fee_calc(csvOutput);
                setDatas(ans);
                const fee =  Math.floor(ans.totalFees.toNumber() * 10000) / 10000
                const asset = ans.underlyingAsset
                const url = "https://twitter.com/intent/tweet?text=過去のトレードで取引所に支払った手数料を計算してみよう！%0a私は過去に" + fee.toString() + asset + "の手数料を支払っていました。%0a%0ahttp://localhost:3000/%0a&via=CoinBack_jpn"
                setTweetUrl(url);
              }
            }else{
              setError('エラーが発生しました');
            }
          }
        };
        fileReader.readAsText(file);
        setIsOpenSendButton(false);
        setIsOpenResult(true);
      }
    }
  };

  return (
    <div className="App">
      <h2 className="message">{message}</h2>
      <div className="main-wrapper">
        <form>
          <div className="form-item" style={{transition: '800ms', opacity: isOpenExchange ? 1 : 0, top: isOpenExchange ? '0px' : '-1000px',}}>
            {exchange_list.map((value) => (
              <><input type={"radio"} name="exchange" onChange={(e) => handleChangeExchange(e)} value={value} id={value} /><label htmlFor={value}></label></>
            ))}
          </div>
          <div className="form-item" style={{transition: '400ms' , opacity: isOpenSelectFile ? 1 : 0, top: isOpenSelectFile ? '0px' : '-1000px'}}>
            <div {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()}/>
              <p>ここにファイルをドラッグするか、クリックしてファイルを選択してください</p>
            </div>
            <p className="howto">※{exchange}の取引履歴のダウンロード方法は<a className="media-link" href={tradeHistoryArticleUrl} target="_blank" rel="noreferrer">こちら</a></p>
            <p>※USDT先物取引の取引履歴のみ対応しています</p>
          </div>
          <div className="form-item submit-area" style={{transition: '400ms' , opacity: isOpenSendButton ? 1 : 0, top: isOpenSendButton ? '0px' : '-1000px'}}>
            <div className="inputed-check">
              <p className="inputed-exchange">取引所：{exchange}</p>
              <p className="file-name">ファイル名：{file.name}</p>
              <p className="lets-reload">※やり直す場合ページをリロード</p>
            </div>
            <button
              onClick={(e) => {
                handleOnSubmit(e);
              }}
            >
              この内容で計算する
            </button>
          </div>
          <div className="result-item" style={{transition: '1000ms', opacity: isOpenResult ? 1: 0, top: isOpenResult ? '0px' : '-1000px'}}>
            <p><span style={{fontSize: '18px'}}>あなたが{exchange}に今までに支払った手数料は</span> <span style={{color: '#E04040', fontSize:"25px", fontWeight: "bold"}}>{paidFees}{underlyingAsset}</span> <span style={{fontSize: '18px'}}>です。</span></p>
            <p><span style={{fontSize: "21px"}}>Coinback</span><span style={{fontSize: '18px'}}>を使っていれば</span> <span style={{color: '#24AE64', fontSize:"25px", fontWeight: "bold"}}>{cashbackFees}{underlyingAsset}</span> <span style={{fontSize: '18px'}}>が<br/>キャッシュバックされていました。</span></p>
            <a className="tweet-btn" href={tweetUrl} target="_blank_" rel="nofollow"><i className="twitter-icon"></i> 結果をツイートする</a>
          </div>
        </form>
      </div>
      <Explain/>
    </div>
  );
}

export default Calculation;
