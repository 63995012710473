import React, { useEffect, useState } from "react";
import "../style.css";
import { db } from "../Firebase";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { Route, Switch, NavLink } from "react-router-dom";
import CreateThread from "../features/CreateThread.tsx";
import "./threadsnav.css";

const ThreadsNav = () => {
  const [threads, setThreads] = useState([]);
  const [open, setOpen] = useState(false);

  const clickModal = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  useEffect(() => {
    const getPostedData = async () => {
      const collectThreads = [];
      const threadRef = collection(db, "thread");
      const q = query(threadRef, orderBy("threadName"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        collectThreads.push(doc.data());
      });
      setThreads(collectThreads);
    };
    getPostedData();
  }, []);

  return (
    <>
      <ul className="scroll_content">
        {threads.map((post) => (
          <li key={post.threadId}>
            <NavLink
              className=""
              to={`/threads/${post.threadId}`}
              state={{ title: post.threadName, id: post.threadId }}
            >
              <button className="thread-title" key={post.threadId}>
                {post.threadName}
              </button>
            </NavLink>
          </li>
        ))}
      </ul>
      {open ? (
        <span onClick={clickModal}></span>
      ) : (
        <button onClick={clickModal} className="thread-title create_thread">
          新しいスレを立てる
        </button>
      )}
      {open ? <CreateThread open={open} setOpen={setOpen} /> : <></>}
    </>
  );
};

export default ThreadsNav;
