import { BigNumber } from "bignumber.js";
import { transaction, isEqualArray } from "../utils.tsx";

export const bybit_fileType = "text/csv"; //Bybit固有

const bybit_header = [
  //Bybit固有
  "Contracts",
  "Order No.",
  "Transaction Time(UTC+0)",
  "Leverage",
  "Direction",
  "Order Type",
  "Filled Type",
  "Filled Qty",
  "Filled Price",
  "Order Price",
  "Trading Fee Rate",
  "Fees Paid",
  "Transaction ID",
];

function bybit_validate(csvHeader: string[]) {
  if (isEqualArray(csvHeader, bybit_header)) {
    return true;
  }
  return false;
}

export function bybit_fee_calc(csvOutput: string) {
  const cashbackRates: number = 0.3; //Bybit固有
  let trades: transaction[] = [];
  let totalFees = new BigNumber(0);
  const csvHeader = csvOutput.slice(0, csvOutput.indexOf("\n")).split(",");
  if (bybit_validate(csvHeader)) {
    const csvRows = csvOutput.slice(csvOutput.indexOf("\n") + 1).split("\n");
    const transactions = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object: transaction, header, index) => {
        object[index] = values[index];
        return object;
      }, {});
      return obj;
    });
    transactions.forEach((object) => {
      if (object[6] === "Trade") {
        //Bybit固有
        trades.push(object);
      }
    });
    trades.forEach((object) => {
      if (object[11] !== undefined) {
        //Bybit固有
        const fee = parseFloat(object[11]); //Bybit固有
        totalFees = totalFees.plus(fee);
      }
    });
    const cashbackFees = totalFees.multipliedBy(cashbackRates);
    const ticker = trades[0][0];

    const usdt = new RegExp("USDT");
    const btcusd = new RegExp("BTCUSD");
    const ethusd = new RegExp("ETHUSD");
    let underlyingAsset = "";
    if (usdt.test(ticker)) {
      underlyingAsset = "USDT";
    } else if (btcusd.test(ticker)) {
      underlyingAsset = "BTC";
    } else if (ethusd.test(ticker)) {
      underlyingAsset = "ETH";
    } else {
      underlyingAsset = "ERROR";
    }
    return {
      trades: trades,
      totalFees: totalFees,
      cashbackFees,
      ticker,
      underlyingAsset,
    };
  } else {
    return {
      trades: trades,
      totalFees: totalFees,
      cashbackFees: totalFees,
      ticker: "ERROR",
      underlyingAsset: "ERROR",
    };
  }
}
