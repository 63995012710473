import React from "react";
import "./header.css";
class Header extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="header_inner">
            <div className="header_left">
              <h1 className="header_ttl">
                <a
                  className="header_ttllink"
                  href="https://coinback-crypto.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="header_logo"
                    src="https://coinback-crypto.com/wp-content/themes/cb/assets/images/common/logo--pc.webp"
                    alt="COINBACK."
                  />
                </a>
              </h1>
            </div>
            <div className="header_right">
              {/* <nav className="header_nav">
                <ul>
                  <a href={`${process.env.PUBLIC_URL}/`}>
                    <li>掲示板トップ</li>
                  </a>
                  <a href={`${process.env.PUBLIC_URL}/calculation`}>
                    <li>手数料計算</li>
                  </a>
                </ul>
              </nav> */}
              <div className="header_user">
                <p className="signup_btn">
                  <a
                    className="signup_btnlink"
                    href="https://app.coinback-crypto.com/signup"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Coinback登録</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </header>
        <div className="header_space"></div>
      </div>
    );
  }
}
export default Header;
