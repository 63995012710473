import { BigNumber } from "bignumber.js";
import { transaction } from "../utils";
import * as XLSX from "xlsx";

export const binance_fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; //Binance固有
const cashbackRates: number = 0.3;

const binance_header = [
  //Binance固有
  "Contracts",
  "Order No.",
  "Transaction Time(UTC+0)",
  "Leverage",
  "Direction",
  "Order Type",
  "Filled Type",
  "Filled Qty",
  "Filled Price",
  "Order Price",
  "Trading Fee Rate",
  "Fees Paid",
  "Transaction ID",
];

export async function binance_fee_calc(file: File) {
  let trades: transaction[] = [];
  let totalFees = new BigNumber(0);
  await file.arrayBuffer().then((buffer: any) => {
    const workbook = XLSX.read(buffer, { type: "buffer", bookVBA: true });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const data: any[] = XLSX.utils.sheet_to_json(worksheet);
    data.forEach((object) => {
      if (object.Type === "COMMISSION") {
        if (object.Asset === "USDT") {
          trades.push(object);
        }
      }
    });
    trades.forEach((object) => {
      const fee = parseFloat(object.Amount.replace(/[^0-9.]/gi, ""));
      totalFees = totalFees.plus(fee);
    });
    const cashbackFees = totalFees.multipliedBy(cashbackRates);

    let underlyingAsset = "USDT";
    const ticker = "USDT-Contract";
    return { trades, totalFees, cashbackFees, ticker: ticker, underlyingAsset };
  });
  const cashbackFees = totalFees.multipliedBy(cashbackRates);
  return {
    trades,
    totalFees,
    cashbackFees,
    ticker: "USDT-Contract",
    underlyingAsset: "USDT",
  };
}
