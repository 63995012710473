import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header.jsx";
// import Footer from "./components/Footer.jsx";
import Main from "./pages/Main";
import Contact from "./pages/Contact.jsx";
import Thread from "./components/Thread.jsx"
import Calculation from "./pages/Calculation.tsx";
import "./style.css";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="contact" element={<Contact />} />
          <Route path="/threads/:id" element={<Thread />} />
          <Route path="/calculation" element={<Calculation />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;