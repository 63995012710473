import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { db } from "../Firebase";
import { collection, getDocs, doc, addDoc, setDoc } from "firebase/firestore";
import "./CreateThread.css";

type Inputs = {
  threadName: string;
  threadId: string;
};

export default function CreateThread(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>({
    criteriaMode: "all",
    shouldFocusError: false,
  });
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const newThreadRef = doc(collection(db, "thread"));
    data.threadId = String(newThreadRef.id);
    await setDoc(newThreadRef, data);
    console.log(data);
    console.log(data.threadId);
    props.setOpen(false);
    reset();
    window.location.reload();
  };

  if (props.open) {
    return (
      <div className="overlay">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            {...register("threadName", {
              required: true,
              minLength: 5,
              maxLength: 20,
            })}
            className="createThreadInput"
          />
          <br />
          {errors.threadName?.types?.required && (
            <p className="error-title">
              タイトルは5~20文字以下で入力してください
            </p>
          )}
          {errors.threadName?.types?.minLength && (
            <p className="error-title">
              タイトルは5~20文字以下で入力してください
            </p>
          )}
          {errors.threadName?.types?.maxLength && (
            <p className="error-title">
              タイトルは20文字以下で入力してください
            </p>
          )}

          <br />
          <input
            type="submit"
            value="スレを立てる"
            className="createThreadBtn"
          />
        </form>
        <button className="close-btn" onClick={() => props.setOpen(false)}>
          &times;
        </button>
      </div>
    );
  } else {
    return null;
  }
}
